<template>
    <div class="site-route-edit">
        <edit :apiSave="'s5/routes?siteId='+siteId" :fs="siteRouteEditFields" @saved="saved" ref="edit" title="Create New Route">
            <template slot="path" slot-scope="{f,model}">
                <md-radio md-value="subdomain" v-model="pathType">Use a Subdomain</md-radio>
                <md-input-container>
                    <md-button class="normal-button">https://</md-button>
                    <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :value="domainInnerValue" @change="handleDomainChange(model,$event)" placeholder="domain" v-validate></md-input>
                    <md-button class="normal-button">.eztimerental.site</md-button>
                    <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                </md-input-container>
                <md-radio md-value="own" v-model="pathType">Connect a Domain you Already Own</md-radio>
                <md-input-container>
                    <md-button class="normal-button">https://</md-button>
                    <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :value="ownDomainInnerValue" @change="handleOwnDomainChange(model,$event)" placeholder="domain" v-validate></md-input>
                    <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                </md-input-container>
            </template>
        </edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { siteRouteEditFields } from "@/config";
export default {
    props: {
        siteId: {
            type: String
        }
    },
    components: {
        Edit
    },
    data() {
        return {
            pathType: "subdomain",
            domainInnerValue: "",
            ownDomainInnerValue: ""
        };
    },

    computed: {
        siteRouteEditFields() {
            return siteRouteEditFields;
        }
    },
    watch: {
        pathType() {
            let edit = this.$refs["edit"];
            console.info(edit);
            if (this.pathType == "subdomain") {
                edit.newRecord["path"] = `${this.domainInnerValue}.eztimerental.site`;
            }
            if (this.pathType == "own") {
                edit.newRecord["path"] = `${this.ownDomainInnerValue}`;
            }
        }
    },
    methods: {
        handleDomainChange(model, val) {
            if (this.pathType == "subdomain") {
                model["path"] = `${val}.eztimerental.site`;
            }
            this.domainInnerValue = `${val}`;
        },
        handleOwnDomainChange(model, val) {
            if (this.pathType == "own") {
                model["path"] = `${val}`;
            }
            this.ownDomainInnerValue = val;
        },
        saved(record) {
            this.$emit("saved", record);
        }
    }
};
</script>

<style lang="less">
.site-route-edit {
    .normal-button {
        text-transform: none;
        background: #efefef;
    }
}
</style>
