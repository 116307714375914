<template>
    <div class="site-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="Site List">
                <site-list :my="false" v-if="tabIndex==0"></site-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" :md-disabled="page!='detail'" md-label="Site Detail">
                <site-detail :id="id" v-if="tabIndex==1"></site-detail>
            </md-tab>
            <md-tab :md-active="tabIndex==2" :md-disabled="page!='edit'" md-label="Edit Site">
                <site-edit :id="id" v-if="tabIndex==2"></site-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==3" :md-disabled="page!='add'" md-label="Add Site">
                <site-edit v-if="tabIndex==3"></site-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==4" :md-disabled="page!='routes'" md-label="Site Routes">
                <site-route-list :id="id" v-if="tabIndex==4"></site-route-list>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import SiteList from "@/components/Site/SiteList";
import SiteDetail from "@/components/Site/SiteDetail";
import SiteEdit from "@/components/Site/SiteEdit";
import SiteRouteList from "@/components/Site/SiteRouteList";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "site-index",
    props: {
        page: {
            type: String,
            default: "list"
        },
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        SiteList,
        SiteDetail,
        SiteEdit,
        SiteRouteList
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "list":
                        this.tabIndex = 0;
                        break;
                    case "detail":
                        this.tabIndex = 1;
                        break;
                    case "edit":
                        this.tabIndex = 2;
                        break;
                    case "add":
                        this.tabIndex = 3;
                        break;
                    case "routes":
                        this.tabIndex = 4;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push("/admin/sites");
            }
            if (index == 1) {
                this.$router.push(`/admin/sites/detail/${this.id}`);
            }
            if (index == 2) {
                this.$router.push(`/admin/sites/${this.id}`);
            }
            if (index == 3) {
                this.$router.push(`/admin/sites/new`);
            }
            if (index == 4) {
                this.$router.push(`/admin/sites/routes/${this.id}`);
            }
        }
    },
    created() {}
};
</script>

