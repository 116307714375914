<template>
    <div class="site-detail" v-if="site">
        <md-card>
            <md-card-header>
                <h2>{{site.url}}</h2>
            </md-card-header>
            <md-card-content>
                <h3>SiteInfo</h3>
                <md-list class="md-double-line">
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>ID</span>
                            <span>{{site.id}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>URI</span>
                            <span>{{site.uri}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>status</span>
                            <span>{{site.status}}</span>
                        </div>
                    </md-list-item>

                    <md-list-item v-if="customer">
                        <div class="md-list-text-container">
                            <span>Customer Name</span>
                            <span>{{customer.firstName + ' ' + customer.lastName}}</span>
                        </div>
                    </md-list-item>

                    <md-list-item v-if="customer">
                        <div class="md-list-text-container">
                            <span>Customer Email</span>
                            <span>{{customer.email }}</span>
                        </div>
                    </md-list-item>

                    <!-- <md-list-item>
                        <div class="md-list-text-container">
                            <span>zone</span>
                            <span>{{site.zone}}</span>
                        </div>
                    </md-list-item>-->
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Container Memory</span>
                            <span>{{site.containerMemory}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Container SSH Port</span>
                            <span>{{site.containerSshPort}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Container SSH Username</span>
                            <span>{{site.containerSshUsername}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Container SSH Password</span>
                            <span>{{site.containerSshPassword}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Container Host Ip Address</span>
                            <span>{{site.containerHostIpAddress}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Created At</span>
                            <span>{{site.createdAt | date}}</span>
                        </div>
                    </md-list-item>
                    <md-list-item>
                        <div class="md-list-text-container">
                            <span>Updated At</span>
                            <span>{{site.updatedAt | date}}</span>
                        </div>
                    </md-list-item>
                </md-list>
                <site-route-list :currentRoute="site.uri" :siteId="id" @autoCert="routeSaved" @promote="routeSaved" ref="siteRouteList"></site-route-list>
            </md-card-content>
            <md-card-actions>
                <md-button @click="$refs['siteRouteEditModal'].open()" class="md-primary" v-if="site.status=='Running'">Add Route</md-button>

                <md-button @click="showConfirm('restart')" class>restart</md-button>
                <md-button @click="showConfirm('start')" class>start</md-button>
                <md-button @click="showConfirm('stop')" class>stop</md-button>

                <md-button @click="showConfirm('deploy')" class="md-primary md-raised">deploy</md-button>
                <md-button @click="showConfirm('undeploy')" class="md-accent md-raised">undeploy</md-button>
            </md-card-actions>
        </md-card>

        <site-route-edit-modal :siteId="id" @saved="routeSaved" ref="siteRouteEditModal"></site-route-edit-modal>
        <confirm @close="actionConfirmed" ref="actionConfirm"></confirm>
    </div>
</template>
<script>
import SiteRouteList from "@/components/Site/SiteRouteList";
import SiteRouteEditModal from "@/components/Site/SiteRouteEditModal";
import Confirm from "@/components/modal/Confirm";

export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
        SiteRouteList,
        SiteRouteEditModal,
        Confirm
    },
    data() {
        return {
            currentAction: null,
            site: null,
            customer: null,
            routes: null
        };
    },

    methods: {
        async getSite() {
            let result = await this.$store.dispatch("crud/get", {
                api: `s5/sites/${this.id}`
            });
            this.site = result.site;
            this.customer = result.customer;
            this.routes = result.routes;
        },

        showConfirm(actionName) {
            this.currentAction = actionName;
            this.$refs["actionConfirm"].open(`Are you sure want to ${actionName} the site?`);
        },
        async actionConfirmed() {
            switch (this.currentAction) {
                case "deploy":
                    await this.deploy();
                    break;
                case "undeploy":
                    await this.undeploy();
                    break;
                case "restart":
                    await this.rstart();
                    break;
                case "start":
                    await this.start();
                    break;
                case "stop":
                    await this.stop();
                    break;
            }

            this.$store.commit("setMessage", "Success");
            this.getSite();
        },
        async deploy() {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/deploy?siteId=" + this.id
            });
        },
        async undeploy() {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/undeploy?siteId=" + this.id
            });
        },
        async restart() {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/restart?siteId=" + this.id
            });
        },
        async start() {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/start?siteId=" + this.id
            });
        },
        async stop() {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/stop?siteId=" + this.id
            });
        },
        routeSaved(record) {
            // let siteRouteList =  this.$refs['siteRouteList']
            window.location.reload();
        }
    },
    created() {
        this.getSite();
    }
};
</script>

<style lang="less">
.site-detail {
    .md-list {
        flex-direction: row;
        flex-wrap: wrap;
        .md-list-item {
            width: 300px;
            span {
                min-height: 30px;
            }
        }
    }
}
</style>
