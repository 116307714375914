<template>
    <div class="site-route-list">
        <list :apiList="'s5/routes?siteId='+siteId" :apiRemove="'s5/routes/:id?siteId='+siteId" :fs="siteRouteListFields" :showPage="false" title="Routes">
            <template slot="list-action" slot-scope="{item}">
                <md-button @click="autoCert(item._id)" class="md-primary md-raised" style="width:100px;" v-if="showAutoCert(item)">Auto Cert</md-button>
                <md-button :disabled="!showPromote(item)" @click="promote(item._id)" class="md-primary md-raised" style="width:100px;">Promote</md-button>
            </template>
        </list>
    </div>
</template>
<script>
import List from "@/components/List";
import { siteRouteListFields } from "@/config";

export default {
    props: {
        siteId: {
            type: String
        },
        currentRoute: {
            type: String
        }
    },
    components: {
        List
    },
    computed: {
        siteRouteListFields() {
            return siteRouteListFields;
        },
        showAutoCert() {
            return item => !/eztimerental.site|wpoutlet.net/.test(item.path);
        },
        showPromote() {
            return item => item.path != this.fixedCurrentRoute;
        },
        fixedCurrentRoute() {
            return (this.currentRoute || "").replace("https://", "");
        }
    },
    methods: {
        async promote(routeId) {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/routes/promote",
                params: {
                    siteId: this.siteId,
                    routeId: routeId
                }
            });
            this.$store.commit("setMessage", "Success");
            this.$emit("promote", routeId);
        },
        async autoCert(routeId) {
            let result = await this.$store.dispatch("crud/post", {
                api: "s5/routes/autoCert",
                params: {
                    siteId: this.siteId,
                    routeId: routeId
                }
            });
            this.$store.commit("setMessage", "Success");
            this.$emit("autoCert", routeId);
        }
    }
};
</script>

