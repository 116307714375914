<template>
    <div class="site-list">
        <list :fs="siteListFields" :showDelete="false" api="s5/sites" ref="list" title="Site List">
            <template slot="list-action" slot-scope="{item}">
                <router-link :to="'/admin/sites/detail/'+item.id" class="md-icon-button" tag="md-button">
                    <md-icon>find_in_page</md-icon>
                </router-link>
                <md-button @click="showDeleteConfirm(item)" class="md-icon-button" v-if="showDeleteButton(item)">
                    <md-icon>delete</md-icon>
                </md-button>
            </template>
        </list>
        <confirm @close="deleteConfirmed" ref="deleteConfirm"></confirm>
    </div>
</template>
<script>
import List from "@/components/List";
import Confirm from "@/components/modal/Confirm";
import { siteListFields } from "@/config";
export default {
    components: {
        List,
        Confirm
    },
    data() {
        return {
            selectedItem: null
        };
    },
    computed: {
        siteListFields() {
            return siteListFields;
        },
        showDeleteButton() {
            return item => item.status == "Undeployed";
        }
    },
    methods: {
        showDeleteConfirm(item) {
            if (!item) {
                return;
            }
            this.selectedItem = item;

            this.$refs.deleteConfirm.open(`Would you like to delete ${item.name ? item.name : ""}?`, null, item);
        },
        async deleteConfirmed(close) {
            if (close == "ok") {
                if (this.selectedItem) {
                    this.$refs.list.selectedItem = this.selectedItem;
                    await this.$refs.list.deleteItem(this.selectedItem);
                }
                this.selectedItem = null;
            }
        }
    }
};
</script>

