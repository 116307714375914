<template>
    <div class="site-route-edit-modal">
        <md-dialog ref="dialog">
            <site-route-edit :siteId="siteId" @saved="saved"></site-route-edit>
        </md-dialog>
    </div>
</template>
<script>
import SiteRouteEdit from "@/components/Site/SiteRouteEdit";
export default {
    props: {
        siteId: {
            type: String
        }
    },
    components: {
        SiteRouteEdit
    },
    methods: {
        open() {
            this.$refs["dialog"].open();
        },
        close() {
            this.$refs["dialog"].close();
        },
        saved(result) {
            this.$emit("saved", result);
            this.close();
        }
    }
};
</script>

