<template>
    <div class="site-edit">
        <edit :fs="siteEditFields" :id="id" @saved="saved" apiSave="s5/sites" title="Add Site">
            <template slot="domain" slot-scope="{f,model}">
                <md-input-container>
                    <label for>{{f.heading}}</label>
                    <md-button class="normal-button">https://</md-button>
                    <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :value="domainInnerValue" @change="handleDomainChange(model,$event)" placeholder="domain" v-validate></md-input>
                    <md-button class="normal-button">.eztimerental.site</md-button>
                    <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                </md-input-container>
            </template>
        </edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { siteEditFields } from "@/config";
export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
        Edit
    },
    data() {
        return {
            domainInnerValue: ""
        };
    },
    computed: {
        siteEditFields() {
            return siteEditFields;
        }
    },
    methods: {
        handleDomainChange(model, val) {
            model["domain"] = `${val}.eztimerental.site`;
        },
        saved() {
            this.$router.push("/admin/sites");
        }
    }
};
</script>
<style lang="less">
.site-edit {
    .normal-button {
        text-transform: none;
        background: #efefef;
    }
}
</style>

